import React from 'react';
import CustomButton from '../shared/CustomButton';

const FloatingNav = () => {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <nav className="fixed bottom-8 left-1/2 transform -translate-x-1/2 z-50 hidden md:block w-auto max-w-[95vw]">
      <div className="bg-white rounded-full shadow-lg px-6 py-3 flex items-center w-max">
        <img 
          src="/pictogramme.png" 
          alt="Logo" 
          className="w-8 h-8 mr-4"
        />
        <div className="flex items-center space-x-4">
          <CustomButton
            onClick={() => scrollToSection('services')}
            variant="secondary"
            size="sm"
            rounded="full"
            hoverEffect="scale"
            className="text-gray-700 hover:text-gray-900 bg-transparent whitespace-nowrap"
          >
            {/* Processus */}
            Services
          </CustomButton>
          <CustomButton
            onClick={() => scrollToSection('prix')}
            variant="secondary"
            size="sm"
            rounded="full"
            hoverEffect="scale"
            className="text-gray-700 hover:text-gray-900 bg-transparent whitespace-nowrap"
          >
            prix
          </CustomButton>
          {/* <CustomButton
            onClick={() => scrollToSection('work')}
            variant="secondary"
            size="sm"
            rounded="full"
            hoverEffect="scale"
            className="text-gray-700 hover:text-gray-900 bg-transparent whitespace-nowrap"
          >
            Projets
          </CustomButton> */}
          {/* <CustomButton
            onClick={() => scrollToSection('resources')}
            variant="secondary"
            size="sm"
            rounded="full"
            hoverEffect="scale"
            className="text-gray-700 hover:text-gray-900 bg-transparent whitespace-nowrap"
          >
            Ressources
          </CustomButton> */}
          <CustomButton
            onClick={() => scrollToSection('faq')}
            variant="secondary"
            size="sm"
            rounded="full"
            hoverEffect="scale"
            className="text-gray-700 hover:text-gray-900 bg-transparent whitespace-nowrap"
          >
            FAQ
          </CustomButton>
          <CustomButton
            variant="primary"
            size="sm"
            rounded="full"
            hoverEffect="both"
            className="flex items-center whitespace-nowrap gap-2"
            onClick={() => window.open('https://cal.com/clement-s/15min', '_blank')}
          >
            <img src="/Clement.png" alt="Clément" className="w-6 h-6 rounded-full" />
            <span>Réservez Un Appel Avec Clément</span>
          </CustomButton>
        </div>
      </div>
    </nav>
  );
};

export default FloatingNav; 