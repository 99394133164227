import React from 'react';

/**
 * Solutions Component
 * 
 * @component
 * @description Section présentant les solutions de recrutement proposées
 */
const Solutions: React.FC = () => {
  const solutions = [
    {
      icon: (
        <svg className="w-16 h-16 mx-auto text-white" viewBox="0 0 24 24" fill="currentColor">
          <path d="M2 20.4l6-2.4V7l-6 2.4v11zm12-11L8 6.4V18l6 2.4V9.4zM14 7l6-2.4v11l-6 2.4V7z"/>
        </svg>
      ),
      title: 'Sourcing Ultra-Rapide',
      description: "Recevez des profils déjà triés, qualifiés et motivés, en quelques jours seulement."
    },
    {
      icon: (
        <svg className="w-16 h-16 mx-auto text-white" viewBox="0 0 24 24" fill="currentColor">
          <path d="M6 16.5l-3 2.94V11h3m5 3.66l-1.57-1.34L8 14.64V7h3m5 6l-3 3V3h3m2.81 9.81L17 11h5v5l-1.79-1.79L13 21.36l-3.47-3.02L5.75 22H3l6.47-6.34L13 18.64"/>
        </svg>
      ),
      title: 'Processus Sans Friction',
      description: "Communication fluide, retours continus et ajustements instantanés tout au long du processus."
    },
    {
      icon: (
        <svg className="w-16 h-16 mx-auto text-white" viewBox="0 0 24 24" fill="currentColor">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/>
        </svg>
      ),
      title: 'Délégation Complète',
      description: "Plus besoin de gérer la partie RH en interne. Nous sommes votre équipe RH externalisée."
    },
    {
      icon: (
        <svg className="w-16 h-16 mx-auto text-white" viewBox="0 0 24 24" fill="currentColor">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z"/>
        </svg>
      ),
      title: 'Marque Employeur',
      description: "Valorisez votre entreprise pour attirer immédiatement les meilleurs talents."
    }
  ];

  return (
    <section className="py-20 px-4 bg-black relative">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_#ffffff11_0%,_transparent_100%)] opacity-50"></div>
      <div className="container mx-auto max-w-4xl relative">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-6xl font-bold mb-4">
            La solution :
            <br />
            un recrutement clé-en-main, axé sur l'action.
          </h2>
          <p className="text-[#ffffffcc] text-xl">
            De la recherche du profil idéal à la signature du contrat, Recruflash s'occupe de tout.
            <br />
            Transformez votre besoin en talents recrutés, en quelques jours seulement.
          </p>
        </div>

        {/* Solutions Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-14">
          {solutions.map((solution, index) => (
            <div key={index} className="text-center flex flex-col">
              <div className="mb-6">
                {solution.icon}
              </div>
              <h3 className="text-xl font-semibold mb-4">{solution.title}</h3>
              <p className="text-[#ffffffcc] text-base">
                {solution.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Solutions; 