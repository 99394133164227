import React from 'react';

interface GridPatternProps {
  children: React.ReactNode;
  dotSize?: number;
  dotColor?: string;
  spacing?: number;
  opacity?: number;
  backgroundColor?: string;
}

const GridPattern: React.FC<GridPatternProps> = ({ 
  children,
  dotSize = 1.9,
  dotColor = '#333',
  spacing = 45,
  opacity = 0.1,
  backgroundColor = 'white'
}) => {
  return (
    <div className={`relative`} style={{ backgroundColor }}>
      <div 
        className="absolute inset-0" 
        style={{
          backgroundImage: `radial-gradient(${dotColor} ${dotSize}px, transparent ${dotSize}px)`,
          backgroundSize: `${spacing}px ${spacing}px`,
          opacity: opacity
        }}
      />
      <div className="relative">
        {children}
      </div>
    </div>
  );
};

export default GridPattern; 