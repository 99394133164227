import React from 'react';
import Navigation from '../components/navigation/Navigation';
import FloatingNav from '../components/navigation/FloatingNav';
import MobileNav from '../components/navigation/MobileNav';
import Hero from '../components/sections/Hero';
import LogoScroll from '../components/sections/LogoScroll';
import Challenges from '../components/sections/Challenges';
import Solutions from '../components/sections/Solutions';
import Testimonials from '../components/sections/Testimonials';
import WrittenTestimonials from '../components/sections/WrittenTestimonials';
import Services from '../components/sections/Services';
import Pricing from '../components/sections/Pricing';
import FAQ from '../components/sections/FAQ';
import CallToAction from '../components/sections/CallToAction';
import Footer from '../components/navigation/Footer';
import HowItWorks from '../components/sections/HowItWorks';
import GridPattern from '../layouts/GridPattern';

/**
 * Home Page Component
 * 
 * @component
 * @description Page d'accueil principale contenant tous les composants de la landing page
 */
function Home() {
  return (
    <div className="min-h-screen bg-black grid-pattern">
      <Navigation />
      <FloatingNav />
      <MobileNav />
      <Hero />
      {/* <LogoScroll /> */}
      <Challenges />
      <Solutions />
      <HowItWorks />
      {/* <Testimonials /> */}
      <Services />
      <Pricing />
      {/* <WrittenTestimonials /> */}
      <FAQ />
      <GridPattern>
        <CallToAction />
        <Footer />
      </GridPattern>
    </div>
  );
}

export default Home; 