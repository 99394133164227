import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Navigation Component
 * 
 * @component
 * @description Affiche la barre de navigation principale avec le logo centré
 */
const Navigation: React.FC = () => {
  return (
    <div className="hidden md:block">
      <nav className="w-full py-2">
        <div className="container mx-auto px-6">
          <div className="flex items-center justify-center">
            <Link to="/">
              <img src="/logo-transparent.png" alt="Recruflash" className="h-12" />
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation; 