import React from 'react';

interface CustomButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'outline' | 'cta';
  rounded?: 'sm' | 'md' | 'lg' | 'full';
  hoverEffect?: 'scale' | 'glow' | 'both' | 'none';
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  onClick,
  className = '',
  size = 'md',
  variant = 'primary',
  rounded = 'lg',
  hoverEffect = 'both',
}) => {
  const sizeClasses = {
    sm: 'px-4 py-2 text-sm',
    md: 'px-6 py-3 text-base',
    lg: 'px-8 py-4 text-lg',
  };

  const variantClasses = {
    primary: 'bg-black text-white hover:bg-gray-800',
    secondary: 'bg-gray-200 text-black hover:bg-gray-300',
    outline: 'border-2 border-black text-black hover:bg-black hover:text-white',
    cta: 'bg-white text-black shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] hover:shadow-[0_2px_15px_-3px_rgba(0,0,0,0.1),0_10px_20px_-2px_rgba(0,0,0,0.06)] font-medium',
  };

  const roundedClasses = {
    sm: 'rounded',
    md: 'rounded-md',
    lg: 'rounded-lg',
    full: 'rounded-full',
  };

  const hoverEffectClasses = {
    scale: 'hover:scale-105 transition-transform duration-300',
    glow: 'hover:shadow-lg transition-shadow duration-300',
    both: 'hover:scale-[1.02] hover:shadow-lg transition-all duration-300',
    none: '',
  };

  const baseClasses = 'font-medium transition-all duration-300 ease-in-out flex items-center justify-center gap-2';

  return (
    <button
      onClick={onClick}
      className={`
        ${baseClasses}
        ${sizeClasses[size]}
        ${variantClasses[variant]}
        ${roundedClasses[rounded]}
        ${hoverEffectClasses[hoverEffect]}
        ${className}
      `}
    >
      {children}
    </button>
  );
};

export default CustomButton; 