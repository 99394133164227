import React from 'react';
import { motion } from 'framer-motion';

/**
 * Services Component
 * 
 * @component
 * @description Section présentant les différents services proposés par l'entreprise
 */
const Services: React.FC = () => {
  const services = [
    "Chasse de têtes & Sourcing Express",
    "Évaluation Poussée des Compétences",
    "Branding Employeur Aiguisé",
    "Process RH Clé-en-Main (de A à Z)",
    "Conseil Stratégique RH",
    "Onboarding Simplifié"
  ];

  return (
    <section className="py-20 px-4 bg-black" id="services">
      <div className="container mx-auto max-w-6xl">
        {/* Section Badge */}
        <div className="flex justify-center mb-4">
          <span className="bg-[#111111] text-white px-4 py-1 rounded-full text-sm">
            Services
          </span>
        </div>

        {/* Section Title */}
        <h2 className="text-4xl md:text-6xl font-bold text-center mb-16">
          Vous nommez le profil,<br />
          nous le trouvons  Recruflash.
        </h2>

        {/* Services Grid */}
        <div className="flex flex-wrap justify-center gap-4">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group"
            >
              <button className="px-6 py-3 rounded-full border border-[#333] hover:border-white text-[#ffffffcc] hover:text-white transition-all duration-300 text-base md:text-lg whitespace-nowrap bg-[#111111] hover:bg-[#222222]">
                {service}
              </button>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services; 