import React, { useState } from 'react';

interface FAQItem {
  question: string;
  answer: string;
}

/**
 * FAQ Component
 * 
 * @component
 * @description Section FAQ avec un design accordéon pour afficher les questions et réponses
 */
const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const faqItems: FAQItem[] = [
    {
      question: "Qui est derrière Recruflash ?",
      answer: "Une équipe RH expérimentée, dédiée à accélérer et simplifier votre recrutement grâce à des process éprouvés et une expertise pointue."
    },
    {
      question: "Pourquoi ne pas recruter en interne ?",
      answer: "Recruter en interne peut se révéler coûteux, lent et chronophage. Nous apportons une expertise pointue, des processus éprouvés et un vivier actif de candidats."
    },
    {
      question: "Que se passe-t-il si je ne suis pas satisfait des candidats ?",
      answer: "Nous réitérons, affinons et itérons jusqu'à trouver le profil idéal. Notre objectif est votre satisfaction et la signature de contrats durables."
    },
    {
      question: "À quelle vitesse vais-je recevoir des candidats ?",
      answer: "Vous recevrez des profils qualifiés en 2-3 jours ouvrables.Souvent plus rapidement"
    },
    {
      question: "Puis-je obtenir un remboursement ?",
      answer: "Notre mission est de délivrer des résultats. Si vous n’êtes pas satisfait, contactez-nous. Nous trouverons une solution adaptée."
    }
  ];

  const toggleQuestion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="faq" className="py-20 px-4 bg-black relative">
      <div className="container mx-auto max-w-4xl">
        <div className="flex flex-col items-center mb-4">
          <span className="px-4 py-1 bg-[#111111] rounded-full text-sm font-medium mb-4">
            FAQs
          </span>
          <h2 className="text-4xl md:text-6xl font-bold text-center mb-16">
            Des questions ? Nous avons les réponses.
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-[2fr_1fr] gap-8">
          {/* FAQ Questions */}
          <div className="space-y-4">
            {faqItems.map((item, index) => (
              <div
                key={index}
                className="bg-[#111111] rounded-2xl overflow-hidden"
              >
                <button
                  className="w-full px-6 py-4 text-left flex items-center justify-between"
                  onClick={() => toggleQuestion(index)}
                >
                  <span className="text-lg font-semibold">{item.question}</span>
                  <span className={`transform transition-transform duration-200 ${openIndex === index ? 'rotate-180' : ''}`}>
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`px-6 overflow-hidden transition-all duration-200 ease-in-out ${
                    openIndex === index ? 'max-h-40 pb-4' : 'max-h-0'
                  }`}
                >
                  <p className="text-[#ffffffcc]">{item.answer}</p>
                </div>
              </div>
            ))}
          </div>

          {/* CTA Card */}
          <div className="relative self-start sticky top-4">
            <div className="absolute inset-0 bg-[radial-gradient(#333_1px,transparent_1px)] [background-size:16px_16px] opacity-10"></div>
            <div className="bg-white rounded-2xl p-6 relative">
              <div className="mb-3">
                <span role="img" aria-label="thinking" className="text-3xl">
                  🤔
                </span>
              </div>
              <h3 className="text-lg font-bold text-black mb-2">
                Vous avez d'autres questions ?
              </h3>
              <p className="text-gray-600 text-sm mb-4">
                Réservez un appel gratuit de consultation.
              </p>
              <button 
                className="w-full bg-black text-white px-6 py-2 rounded-full text-sm font-semibold hover:bg-gray-900 transition-colors"
                onClick={() => window.open('https://cal.com/clement-s/15min', '_blank')}
              >
                Réserver un appel gratuit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ; 