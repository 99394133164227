import React from 'react';

const HowItWorks: React.FC = () => {
  const steps = [
    {
      number: "1",
      title: "Souscrire",
      description: "Choisissez une formule adaptée. Aussitôt inscrit, vous êtes accompagné par un(e) RH dédié(e)."
    },
    {
      number: "2",
      title: "Demander",
      description: "Soumettez vos besoins. Nous gérons une demande à la fois (Standard) ou plusieurs en parallèle (Growth). Pas de stress, pas d'effort de votre côté."
    },
    {
      number: "3",
      title: "Recevoir",
      description: "Sous 2-3 jours ouvrables, nous vous présentons des candidats présélectionnés, prêts à être embauchés. Vous n'avez plus qu'à dire \"oui\"."
    }
  ];

  return (
    <section className="py-20 px-4 bg-black">
      <div className="container mx-auto max-w-6xl">
        {/* Card with border */}
        <div className="border-2 border-white/40 rounded-[40px] p-16 bg-[#111111]">
          {/* Section Badge */}
          <div className="text-center mb-16">
            <span className="bg-black text-white px-4 py-1 rounded-full text-sm inline-block mb-4">
              Comment ça Marche ?
            </span>
            <h2 className="text-4xl md:text-6xl font-bold mb-4">
              Simple. Rapide. Efficace.
            </h2>
          </div>

          {/* Steps */}
          <div className="grid md:grid-cols-3 gap-16 relative mb-16">
            {/* Connecting Lines */}
            <div className="hidden md:block absolute top-[20%] left-[25%] w-[50%] h-[2px]">
              <div className="w-full h-full bg-gradient-to-r from-[#333] to-[#333] relative">
                <div className="absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 rotate-45">
                  →
                </div>
              </div>
            </div>

            {steps.map((step, index) => (
              <div key={index} className="text-center relative">
                {/* Step Number */}
                <div className="text-[120px] font-bold leading-none mb-6 text-[#333] transition-colors duration-300 group-hover:text-white">
                  {step.number}
                </div>
                
                {/* Step Content */}
                <h3 className="text-2xl font-bold mb-4">
                  {step.title}
                </h3>
                <p className="text-[#ffffffcc] text-base">
                  {step.description}
                </p>
              </div>
            ))}
          </div>

          {/* Footer Info */}
          <div className="flex flex-wrap justify-center items-center gap-8 text-sm text-[#ffffffcc]">
            <div className="flex items-center gap-2">
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"/>
              </svg>
              Paiement 100% sécurisé via Stripe
            </div>

            <div className="flex items-center gap-2">
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"/>
              </svg>
              Communication fluide (Chat, Email)
            </div>

            <div className="flex items-center gap-2">
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"/>
              </svg>
              Candidats validés par des Experts
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks; 