import React, { useState } from 'react';
import CustomButton from '../shared/CustomButton';

const MobileNav: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      setIsMenuOpen(false);
    }
  };

  return (
    <>
      {/* Header fixe avec logo et bouton menu */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-b from-black/30 to-black/5 md:hidden">
        <div className="flex justify-between items-center px-6 py-4">
          <img 
            src="/logo-transparent.png" 
            alt="Recruflash" 
            className="h-12"
          />
          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="bg-white rounded-md p-2 w-12 h-12 flex items-center justify-center"
            aria-label={isMenuOpen ? 'Fermer le menu' : 'Ouvrir le menu'}
          >
            <svg 
              width="32" 
              height="32" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="black" 
              strokeWidth="3"
              className="transition-transform duration-200"
            >
              {isMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M3 6h18M3 12h18M3 18h18" />
              )}
            </svg>
          </button>
        </div>

        {/* Menu déroulant avec transparence optimisée */}
        {isMenuOpen && (
          <div className="relative">
            {/* Fond avec effet de verre */}
            <div className="absolute inset-0 bg-gradient-to-b from-black/5 to-black/0" />
            
            {/* Contenu du menu au premier plan */}
            <div className="relative border-t border-white/5">
              <div className="flex flex-col space-y-3 p-4 max-h-[calc(100vh-80px)] overflow-y-auto">
                <button
                  onClick={() => scrollToSection('services')}
                  className="text-white text-left py-3 px-4 hover:bg-white/10 rounded-lg transition-all duration-200 font-semibold backdrop-blur-[0.5px]"
                >
                  Services
                </button>
                <button
                  onClick={() => scrollToSection('prix')}
                  className="text-white text-left py-3 px-4 hover:bg-white/10 rounded-lg transition-all duration-200 font-semibold backdrop-blur-[0.5px]"
                >
                  Prix
                </button>
                <button
                  onClick={() => scrollToSection('faq')}
                  className="text-white text-left py-3 px-4 hover:bg-white/10 rounded-lg transition-all duration-200 font-semibold backdrop-blur-[0.5px]"
                >
                  FAQ
                </button>
                <button
                  onClick={() => window.open('https://cal.com/clement-s/15min', '_blank')}
                  className="flex items-center gap-2 justify-center w-full mt-4 bg-[#1E1E1E] hover:bg-[#2D2D2D] text-white py-3 px-4 rounded-full transition-all duration-200 font-semibold shadow-lg"
                >
                  <img src="/Clement.png" alt="Clément" className="w-6 h-6 rounded-full" />
                  <span>Réservez Un Appel Avec Clément</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Spacer ajusté */}
      <div className="h-[80px] md:hidden" />
    </>
  );
};

export default MobileNav; 