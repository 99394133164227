import React from 'react';
import CustomButton from '../shared/CustomButton';

const getCurrentMonthInFrench = () => {
  const months = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];
  return months[new Date().getMonth()];
};

const CallToAction: React.FC = () => {
  const currentMonth = getCurrentMonthInFrench();
  
  return (
    <section className="py-32 px-4 relative">
      <div className="container mx-auto max-w-3xl text-center relative">
        <h2 className="text-4xl md:text-6xl font-bold mb-8 leading-tight text-black">
          Ne laissez plus passer les meilleurs talents !
        </h2>
        
        <CustomButton
          size="lg"
          variant="primary"
          rounded="full"
          hoverEffect="both"
          className="flex items-center mx-auto font-bold"
          onClick={() => window.open('https://cal.com/clement-s/15min', '_blank')}
        >
          Réservez votre appel gratuit dès maintenant 🚀
          <svg className="w-6 h-6 ml-2" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M11.3 3.3a1 1 0 0 1 1.4 0l6 6a1 1 0 0 1 0 1.4l-6 6a1 1 0 0 1-1.4-1.4l4.3-4.3H3a1 1 0 0 1 0-2h12.6l-4.3-4.3a1 1 0 0 1 0-1.4z" clipRule="evenodd" />
          </svg>
        </CustomButton>
        
        <p className="mt-6 text-gray-600 text-sm">
          Dépêchez-vous ! Les places sont presque toutes prises pour {currentMonth}.
        </p>
      </div>
    </section>
  );
};

export default CallToAction; 