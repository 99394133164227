import React from 'react';
import { motion } from 'framer-motion';
import { StarIcon } from '@heroicons/react/24/solid';
import CustomButton from '../shared/CustomButton';

/**
 * Hero Component
 * 
 * @component
 * @description Section principale de la page d'accueil présentant la proposition de valeur de Recruflash
 */
const Hero: React.FC = () => {
  return (
    <section className="pt-4 pb-8 px-4">
      <div className="container mx-auto max-w-6xl text-center">
        {/* Testimonial Badge */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="inline-flex items-center gap-2 bg-[#111111] rounded-full px-3 py-1.5 mb-12 backdrop-blur-sm"
        >
          <div className="flex -space-x-1.5">
            {[1, 2, 3, 4].map((num) => (
              <img 
                key={num}
                className="w-6 h-6 rounded-full border-2 border-black" 
                src={`https://randomuser.me/api/portraits/${num % 2 ? 'men' : 'women'}/${num}.jpg`} 
                alt="Client" 
              />
            ))}
            <div className="w-6 h-6 rounded-full border-2 border-black bg-gray-900 flex items-center justify-center text-[10px] font-medium">+46</div>
          </div>
          <div className="flex items-center gap-0.5">
            {[...Array(5)].map((_, i) => (
              <StarIcon key={i} className="w-4 h-4 text-yellow-400" />
            ))}
          </div>
          <span className="text-xs font-medium text-[#ffffffcc]">50+ Dirigeants et DRH nous font déjà confiance</span>
        </motion.div>

        {/* Main Heading */}
        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-4xl md:text-[60px] font-black mb-8 tracking-[-0.02em] leading-[1.1] max-w-5xl mx-auto"
        >
          <div className="flex flex-col items-center">
            <span>Recruflash : La Gestion RH</span>
            <span className="mt-2">Externalisée, en Mode Ultra-Rapide</span>
          </div>
        </motion.h1>

        {/* Subtitle */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="mb-12"
        >
          <p className="text-[#ffffffcc] text-xl max-w-2xl mx-auto font-normal">
            Votre équipe RH sur-mesure, sans recruter en interne. Transformez votre besoin en talents recrutés, en quelques jours.
          </p>
        </motion.div>

        {/* CTA Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="flex justify-center"
        >
          <CustomButton
            size="lg"
            variant="cta"
            rounded="lg"
            hoverEffect="both"
            className="shadow-xl font-semibold flex items-center gap-2"
            onClick={() => window.open('https://cal.com/clement-s/15min', '_blank')}
          >
            <img src="/Clement.png" alt="Clément" className="w-6 h-6 rounded-full" />
            Réservez Votre Appel Stratégique Gratuit
          </CustomButton>
        </motion.div>

        {/* Bottom Text */}
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="text-[#ffffff99] text-sm mt-4 mb-2 font-light italic"
        >
          Places Limitées Ce Mois-ci • Délégation Totale • Résultats Garantis
        </motion.p>
      </div>
    </section>
  );
};

export default Hero; 