import React from 'react';

/**
 * Challenges Component
 * 
 * @component
 * @description Section highlighting key recruitment challenges and Recruflash's solution
 */
const Challenges: React.FC = () => {
  const challenges = [
    {
      icon: '⏱️',
      title: 'Lenteur Chronique',
      description: "Les processus de recrutement internes vous font perdre un temps précieux, alors que le marché du talent bouge à grande vitesse."
    },
    {
      icon: '🌊',
      title: 'Noyé dans les CVs',
      description: "Trop de candidatures, pas assez de talents réellement pertinents. Votre équipe se noie dans un flux de candidatures inexploitables."
    },
    {
      icon: '🏃‍♂️',
      title: 'Opportunités Manquées',
      description: "Chaque jour de retard dans votre recrutement est une opportunité de talent perdue au profit de vos concurrents."
    }
  ];

  return (
    <section className="py-20 px-4 bg-black">
      <div className="container mx-auto max-w-3xl">
        {/* Main Challenge Text */}
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-6xl font-bold mb-4">
            Vous n'avez pas un problème de candidatures...
            <br />
            Vous avez un problème de vitesse et d'efficacité.
          </h2>
          <p className="text-[#ffffffcc] text-xl">
            Le véritable défi : transformer rapidement les talents en collaborateurs performants.
          </p>
        </div>

        {/* Challenges Grid */}
        <div className="grid md:grid-cols-3 gap-14">
          {challenges.map((challenge, index) => (
            <div key={index} className="text-center">
              <div className="text-5xl mb-6">{challenge.icon}</div>
              <h3 className="text-xl font-semibold mb-4">{challenge.title}</h3>
              <p className="text-[#ffffffcc] text-base">
                {challenge.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Challenges; 