import React from 'react';
import Navigation from '../components/navigation/Navigation';
import FAQ from '../components/sections/FAQ';
import Footer from '../components/navigation/Footer';
import GridPattern from '../layouts/GridPattern';

/**
 * Resources Page Component
 * 
 * @component
 * @description Page de ressources contenant la FAQ et le footer
 */
function Resources() {
  return (
    <div className="min-h-screen bg-black grid-pattern">
      <Navigation />
      <FAQ />
      <GridPattern>
        <Footer />
      </GridPattern>
    </div>
  );
}

export default Resources; 